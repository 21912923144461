<template>
    <div class="payment-kbank">
        <v-form ref="form" id="form" v-model="valid" lazy-validation>
            <layout :header="$i18n.locale == 'th' ? 'ชำระด้วย KBANK' :'Pay BY KBANK'"
                @goback="$router.push('/PaymentDetail/' + $route.params.id)" :bodyStrong="false"
                :class="{'blur-page': paymentConfirm}">
                <template v-slot:content>
                    <v-col cols="12" class="pa-0" xs="12" sm="7" md="5" lg="4">
                        <v-row class="px-3 payment-kbank-body" no-gutters>
                            <v-col cols="12" class="mt-5 mb-2 text-center">
                                <div>{{$i18n.locale == 'th' ? couponById.name: couponById.name_en}}</div>
                                <div>
                                    <h1>{{formatMoney(couponById.price)}} {{$i18n.locale == 'th' ? 'บาท': 'THB'}}</h1>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-subheader class="pl-0">
                                    {{$i18n.locale == 'th' ? 'ช่องทางการชำระเงิน': 'Payment Method'}}</v-subheader>
                                <v-expansion-panels focusable mandatory>
                                    <v-expansion-panel @click="selectedPaying('creditcard')">
                                        <v-expansion-panel-header>Credit Card</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row align="center" class="mt-5">
                                                <v-col cols="4">
                                                    <v-img src="/Image/visa@3x.png" alt cover></v-img>
                                                </v-col>
                                                <v-col cols="8">{{$i18n.locale=='th' ? 'จ่ายด้วย': 'Pay with'}}
                                                    VISA,DEBIT,MASTER CARD</v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel @click="selectedPaying('bank_qrcode')">
                                        <v-expansion-panel-header>Bank QRCODE</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row align="center" class="mt-5">
                                                <v-col cols="3" class="pa-5">
                                                    <v-img src="/Image/qr.png" alt cover></v-img>
                                                </v-col>
                                                <v-col cols="9">{{$i18n.locale=='th' ? 'จ่ายด้วย': 'Pay with'}} QRCODE
                                                    Banking</v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-col>
                </template>
            </layout>
            <v-btn class="bottom-button" block height="50px" @click="pay()">{{$i18n.locale == 'th' ? 'ชำระ': 'PAY'}}
            </v-btn>
        </v-form>
        <pin-pad v-show="paymentConfirm" :backward="true"
            :header="$i18n.locale == 'th' ? 'กรุณายืนยัน PINCODE': 'Please enter your<br>PINCODE'" :clear="clearPin"
            @pinEmit="verifyPin" @goback="paymentConfirm=false;"></pin-pad>
        <loading v-show="loadingState"></loading>
        <alert :header="$t('failed')" type="error" :title="textErr" v-show="showAlertError"
            @done="showAlertError=false;"></alert>
        <alert :header="$t('success')" type="paySuccess"
            :title="$i18n.locale == 'th' ? 'การทำรายการเรียบร้อย <br> กรุณาชำระเงินแล้วรอรับคูปอง' :'The transaction is completed. <br> Please pay and receive the coupon.'"
            v-show="showAlertSuccess" @done="showAlertSuccess = false;"></alert>
        <form method="POST" action="https://app-api.casalapin.com/pay_qr" ref="form_pay">
            <input type="hidden" name="order_id" ref="qr_id" />
            <input type="hidden" name="amount" ref="qr_amount" />
        </form>
        <form method="POST" action="https://app-api.casalapin.com/pay_card" ref="form_pay_card">
            <input type="hidden" name="coupon_id" ref="card_couponId" />
            <input type="hidden" name="x_parse_session_token" ref="card_sessionToken" />
        </form>
    </div>
</template>

<script>
    import Alert from "@/components/alert";
    import Layout from "@/components/layout_1";
    import Pinpad from "@/components/pinPad";
    import Loading from "@/components/loading";
    import CryptoJS from "crypto-js";
    export default {
        name: "PaymentConfirm",
        components: {
            layout: Layout,
            "pin-pad": Pinpad,
            loading: Loading,
            alert: Alert,
        },
        data() {
            return {
                paymentConfirm: false,
                showAlertSuccess: false,
                clearPin: false,
                loadingState: false,
                textErr: "",
                showAlertError: false,
                valid: true,
                typeOfPay: "creditcard",
            };
        },
        computed: {
            eKYC_phone() {
                return this.$store.getters.eKYC_phone;
            },
            couponById() {
                return this.$store.getters.couponById(this.$route.params.id);
            },
        },
        methods: {
            selectedPaying(type) {
                this.typeOfPay = type;
            },
            async pay() {
                let valid = this.$refs.form.validate();
                if (valid) {
                    if (localStorage.getItem("pinReq_casalapin") == "true") {
                        this.paymentConfirm = true;
                    } else {
                        try {
                            let paymentUrl = await this.callTopay();
                        } catch (err) {
                            this.errAlert(
                                this.$i18n.locale == "th" ?
                                "การจ่ายเงินไม่สำเร็จ<br> กรุณาลองใหม่อีกครั้ง" :
                                "checkout failed<br> Please try again."
                            );
                        }
                    }
                }
            },
            async verifyPin(val) {
                this.loadingState = true;
                try {
                    const config = {
                        headers: {
                            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
                        }
                    }
                    let params = {
                        pin: val,
                    };
                    let response = await this.$axios.post(
                        "/verify_pin",
                        params,
                        config
                    );
                    let verifyPin = response.data;
                    if (verifyPin.status == 200) {
                        this.paymentConfirm = await false;
                        this.clearPin = await !this.clearPin;
                        try {
                            let paymentUrl = await this.callTopay();
                        } catch (err) {
                            this.errAlert(
                                this.$i18n.locale == "th" ?
                                "การจ่ายเงินไม่สำเร็จ<br> กรุณาลองใหม่อีกครั้ง" :
                                "checkout failed<br> Please try again."
                            );
                        }
                    } else if (verifyPin.status == 400) {
                        if (verifyPin.lock <= 0) {
                            this.errAlert(
                                this.$i18n.locale == "th" ?
                                `รหัสผ่านไม่ถูกต้อง<br>attemped ${verifyPin.attempted}/3` :
                                `Password has invalid<br>attemped ${verifyPin.attempted}/3`
                            );
                        } else {
                            this.errAlert(
                                this.$i18n.locale == "th" ?
                                `กรุณาลองใหม่อีกครั้ง<br> ภายในอีก ${verifyPin.lock} วินาที` :
                                `Please try again<br> in ${verifyPin.lock} sec.`
                            );
                        }
                    } else {
                        this.errAlert(
                            this.$i18n.locale == "th" ?
                            "ไม่พบรหัสผ่าน <br> กรุณา OTP ใหม่อีกครั้ง" :
                            "Password notfound<br>Please OTP again"
                        );
                    }
                } catch (err) {
                    console.log(err);
                    this.errAlert(
                        this.$i18n.locale == "th" ?
                        "กรุณาเช็คการเชื่อมต่อ" :
                        "Please check connection."
                    );
                }
                this.loadingState = false;
            },
            async callTopay(val) {
                try {
                    if (this.typeOfPay == "creditcard") {
                        this.$refs.card_couponId.value = this.couponById.objectId;
                        this.$refs.card_sessionToken.value = localStorage.getItem('sessionToken_casalapin');
                        this.$refs.form_pay_card.submit();
                    } else if (this.typeOfPay == "bank_qrcode") {
                        const config = {
                            headers: {
                                'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
                            }
                        }
                        let params = {
                            coupon_id: this.couponById.objectId
                        };
                        let response = await this.$axios.post(
                            "https://app-api.casalapin.com/create_qr_purchase_order", params, config);
                        this.$refs.qr_id.value = response.data.data.id;
                        this.$refs.qr_amount.value = response.data.data.amount;
                        this.$refs.form_pay.submit();
                    }
                } catch (err) {
                    throw err;
                }
            },
            errAlert(msg) {
                this.clearPin = !this.clearPin;
                this.textErr = msg;
                this.showAlertError = true;
            },
            validate() {
                this.$refs.form.validate();
            },
            reset() {
                this.$refs.form.reset();
            },
            resetValidation() {
                this.$refs.form.resetValidation();
            },
        },
        created() {}
    };
</script>

<style lang="scss">
    @import "@/assets/scss/color.scss";

    .payment-kbank {
        height: 100%;

        .payment-kbank-body {
            font-weight: 800;
            font-size: 15px;
            padding-bottom: 60px;
        }

        .input-pay-text {
            .v-input__slot {
                padding-right: 8px !important;
            }

            fieldset {
                border: 2px solid $accent-color;
            }

            .v-input__append-inner {
                margin-top: 4px !important;
            }

            // width: 100%;
            // border: 2px solid $accent-color;
            // padding: 10px 10px 10px 20px;
            // border-radius: 24px;
            // height: 48px;
            // color: $base-color;
            // font-size: 14px;
            // margin-top: 16px;
            // font-weight: 800;
        }

        .v-input--is-focused {
            fieldset {
                border: 2px solid $base-color;
            }
        }

        .input-pay-check {
            .v-label {
                color: $primary-color !important;
            }
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $accent-color;
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $accent-color;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $accent-color;
        }

        .input-pay-text:focus {
            outline: none;
        }

        .bottom-button {
            z-index: 1;
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: $primary-color !important;
            border-radius: 0px !important;
        }
    }
</style>