<template>
  <v-container class="layout-1 pa-0" fluid>
    <v-row class="layout-header" align="center" no-gutters>
      <v-col cols="2" class="pl-1">
        <div
          v-ripple="{ class: 'riple' }"
          class="pa-1 white--text d-flex align-center"
          style="height:auto;width:3em;"
          @click="$emit('goback')"
        >
          <img src="/Image/back@2x.png" style="height:auto;width:100%;" />
        </div>
      </v-col>
      <v-col cols="8" class="header pt-1">{{header}}</v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row class="layout-body" :class="{'body-strong':bodyStrong}" dense align-content="start" justify="center">
      <slot name="content"></slot>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Layout1",
  props: {
    header: {
      type: String,
      default: "",
    },
    backward: {
      type: Boolean,
      default: false,
    },
    bodyStrong: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";

.layout-1 {
  padding: 0!important;
  height: 100%;
  display: flex;
  flex-flow: column;

  .layout-header {
    position: fixed;
    flex: 0 1 70px;
    z-index: 5;
    background-image: url("/Image/BG_2.png");
    background-size: cover;
    background-position: center top;
    height: 70px;
    min-width: 100%;

    .header {
      color: white;
      font-size: 17px;
      font-weight: 800;
      text-align: center;
    }
  }

  .layout-body {
    padding: calc(100vh * 0.09) 10px 10px 10px;
    background: url("/Image/BG_4.png");
    background-size: contain;
    background-repeat: repeat;
    width: 100%;
    margin: 0px !important;
    min-height: calc(100vh * 0.92);
    height: auto;
  }

  @media only screen and (max-height: 620px) {
    .layout-body {
      padding: 70px 10px 10px 10px;
    }
  }

  .body-strong {
    background-image: url("/Image/BG_3.png");
  }
}
</style>