<template>
  <transition name="fade" mode="in-out">
    <v-container fluid class="alertComponent">
      <v-row align-content="center">
        <v-col cols="12" align-self="center">
          <v-card class="mx-auto card" max-width="290" outlined>
            <div class="header-alert">{{header}}</div>

            <v-img v-if="type=='success' || type=='paySuccess'" src="/Image/success.png" height="100" contain></v-img>

            <v-img v-else-if="type=='error'" src="/Image/error.png" height="100" contain></v-img>

            <div class="title-alert" v-html="title"></div>

            <v-card-actions class="d-flex justify-center pa-0" style="flex-direction:column;">
              <v-btn v-if="type=='success' || type=='error'" rounded large block dark color="#463729" @click="$emit('done')">Done</v-btn>
              <v-btn v-if="type=='paySuccess'" rounded large block dark color="#463729" @click="$router.push('/')">Return to shop menu</v-btn>
              <v-btn v-if="type=='paySuccess'" class="mt-3" rounded large block dark color="#9D662C" @click="$router.push('/Purchases')">Go to My Purchases menu</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </transition>
</template>

<script>
  export default {
    name: "AlertComponent",
    props: {
      header: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "error"
      },
      title: {
        type: String,
        default: "",
      },
    },
  };
</script>

<style lang="scss">
  .alertComponent {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6) !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;

    .card {
      border-radius: 8px;
      padding: 24px;
    }

    .header-alert {
      font-size: 18px !important;
      font-weight: 800;
      text-align: center;
      padding-bottom: 28px;
    }

    .title-alert {
      font-size: 16px !important;
      font-weight: 800;
      text-align: center;
      padding: 28px 0px;
    }
  }
</style>